<template>
  <div class="ym-page">

    <!-- 顶部搜索栏 -->
    <!-- <div class="tableList-form">
      <ymForm ref="tableForm" refCode="tableForm"
        :formValue="formValue"
        :formData="formData"
        :inline="true"
        @onSubmit="onSubmit">
      </ymForm>
      <div class="tableList-form-buttonBox">
        <el-button size="medium" @click="resetForm('formValue')" plain >重置</el-button>
        <el-button size="medium" @click="submitForm('tableForm')" type="primary">查询</el-button>
      </div>
    </div> -->

    <!-- 列表组件 -->
    <div class="table-group">
      <ym-table-list
        :headData="headData"
        :data="tableData"
        :columns="columns"
        :pageData="pageData"
        :tableOption="{size: 'mini'}"
        :loading="loading"
        fit
        stripe
        row-key="id"
        @handlePageChange="handlePageChange"
        @handleSizeChange="handleSizeChange">
        <!-- 使用列表插槽 -->
        <template #createTime="{row}">
          {{ tf(row.createTime, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
      </ym-table-list>
    </div>

    <!-- 新增表单 -->
    <el-dialog class="tableList-addForm" width="800px" :title="dialogTitle" :visible.sync="dialogShow" :close-on-click-modal="false" @close="cancel">
      <ymForm label-position="left"
        ref="addForm"
        refCode="addForm"
        :formValue="dialogValue"
        :formData="dialogForm"
        @onSubmit="onSubmit">
        <template #role>
          <el-select v-model="roleValue" multiple placeholder="请选择" value-key="roleID" style="width: 100%">
            <el-option v-for="item in roleList" :key="item.roleID" :label="item.roleName" :value="item"></el-option>
          </el-select>
        </template>
      </ymForm>
      <div class="tableList-form-buttonBox">
        <el-button v-show="dialogForm[0]&&dialogForm[0].showbutton" size="medium" @click="submitForm('addForm')" type="primary">确认 </el-button>
      </div>
    </el-dialog>

    <!--新密码-->
    <el-dialog class="tableList-addForm" width="800px" :title="dialogTitle" :visible.sync="passwordDialogShow" :close-on-click-modal="false">
      <template v-if="dialogContent === 'resetPassword'">
        <div class="passwordContent" style="text-align: center;">确认是否重置密码？</div>
        <div class="tableList-form-buttonBox">
          <el-button @click="passwordDialogShow = false">否</el-button>
          <el-button type="primary" @click="newPassword">是</el-button>
        </div>
      </template>
      <template v-else>
        <el-form class="passwordContent" label-width="80px">
          <el-form-item label="密码：">
            <el-col :span="18">{{passwordValue}}</el-col>
            <el-col :span="6"><el-button type="text" @click="passwordCopy($event)">复制</el-button></el-col>
          </el-form-item>
        </el-form>
        <div class="tableList-form-buttonBox">
          <el-button size="medium" @click="passwordDialogShow = false" type="primary">关闭</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import ymForm from '@/components/YmForm'
import tableObj from './data.js'
import clipboard from '@/utils/clipboard.js'
import timeFormat from '@/utils/timeFormat.js'
const crypto = require('crypto')
export default {
  components: {
    YmTableList,
    ymForm
  },
  data() {
    return {
      loading: false,
      tableData: [],
      formValue: {},
      dialogTitle: '',
      dialogShow: false,
      dialogValue: {},
      dialogForm: [],
      tableObj: tableObj,
      columns: tableObj.columnsData,
      pageData: tableObj.pageData,
      addForm: tableObj.addForm,
      formData: tableObj.formData,
      headData: tableObj.headData,
      dialogContent: '',
      passwordDialogShow: false,
      passwordValue: '',
      roleList: [],
      roleValue: []
    }
  },
  mounted() {
    this.getData()
    this.getRoleList()
  },
  methods: {
    // 点击取消
    cancel() {
      // console.log('取消事件')
      this.$refs.addForm.resetFields()
    },
    // 哈希加密(SHA1)
    encrypt(password) {
      const hash = crypto.createHash('sha1')
      hash.update(password)
      return hash.digest('hex')
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    // 获取角色列表
    async getRoleList() {
      let res = await this.$api.rbac.listRoles()
      this.roleList = res.data
      // let role = res.data.map(item => {
      //   return { label: item.roleName, value: item, key: item.roleID }
      // })
      // this.tableObj.addForm[0].items.role.children = role
      // this.tableObj.editForm[0].items.role.children = role
    },
    // 获取用户信息
    async getAccount(id) {
      let res = await this.$api.rbac.getAccount({ accountID: id }) // 查询用户数据
      this.dialogValue = res.data
    },
    // 查询账户角色
    async getAccountRoles(id) {
      let res = await this.$api.rbac.getAccountRoles({ accountID: id })
      // this.dialogValue.role = res.data
      this.roleValue = res.data.roles
    },
    // 获取表格数据
    async getData() {
      this.loading = true
      let param = {
        page: this.pageData.currentPage - 1,
        size: this.pageData.size
      }
      let res = await this.$api.rbac.listAccount(param)
      this.tableData = res.data.content
      this.pageData.total = res.data.total
      this.loading = false
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData()
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData()
    },
    // 重置表单
    resetForm(key) {
      this.$set(this, key, {})
      this.getData()
    },
    // 点击提交按钮,触发提交事件
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    // 提交事件
    async onSubmit(form, ref) {
      if (!form.accountID) {
        let data = JSON.parse(JSON.stringify(form))
        data.password = this.encrypt(data.password)
        let res = await this.$api.rbac.addAccount(data)
        if (res.code === 200) {
          let params = {
            account: res.data,
            roles: this.roleValue
          }
          let res1 = await this.$api.rbac.addAccountRole(params)
          if (res1.code === 200) {
            this.dialogShow = false
            this.dialogValue = {}
            this.getData()
            this.$message.success('添加数据成功')
          }
        } else {
          this.$message.error(res.message)
        }
      } else {
        // 编辑
        let params = {
          account: form,
          roles: this.roleValue
        }
        // console.log(params, this.roleValue)
        let res = await this.$api.rbac.addAccountRole(params)
        if (res.code === 200) {
          this.dialogShow = false
          this.dialogValue = {}
          this.getData()
          this.$message.success('更新角色成功')
        } else {
          this.$message.error(res.message)
        }
      }
    },
    // 删除
    deleteRow(row) {
      let thisVue = this
      this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await thisVue.$api.rbac.deleteAccount(row)
        if (res.code === 200) {
          thisVue.getData()
          thisVue.$message.success('删除成功')
        } else {
          thisVue.$message.error(res.message)
        }
      })
    },
    // 获取新密码
    async newPassword(row) {
      this.dialogTitle = '新密码'
      this.dialogContent = 'newPassword'
      this.passwordValue = '123456'
    },
    passwordCopy(event) {
      clipboard(event, this.passwordValue, () => {
        this.$message.success('复制成功')
      }, () => {
        this.$message.error('复制失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-input {
  width: 84% !important;
}
.tableList-addForm{
  /deep/.el-dialog{
    max-width: 600px;
    .el-dialog__body{
      padding: 30px 80px 50px;
      .tableList-form-buttonBox{
        text-align: center;
      }
    }
    .passwordContent{
      margin: 20px 0 50px 0;
    }
  }
}
/deep/.tableList-addForm .el-dialog .el-dialog__body .tableList-form-buttonBox {
    text-align: right;
    margin-top: 16px;
}
</style>
